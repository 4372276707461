import React, { lazy, Suspense } from 'react';

const LazyProofDialogWrapper = lazy(() =>
  import('./components/proofDialog').then((module) => ({ default: module.ProofDialogWrapper }))
);

const LazyGenStudioSetup = lazy(() =>
  import('./pages/genStudioSetup').then((module) => ({ default: module.GenStudioSetup }))
);

export function ProofDialogWrapper(props) {
  return (
    <Suspense fallback={null}>
      <LazyProofDialogWrapper {...props} />
    </Suspense>
  );
}

export function GenStudioSetup(props) {
  return (
    <Suspense fallback={null}>
      <LazyGenStudioSetup {...props} />
    </Suspense>
  );
}
